// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-qaf-js": () => import("./../../../src/pages/about-qaf.js" /* webpackChunkName: "component---src-pages-about-qaf-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-participants-js": () => import("./../../../src/pages/participants.js" /* webpackChunkName: "component---src-pages-participants-js" */),
  "component---src-pages-programme-index-js": () => import("./../../../src/pages/programme/index.js" /* webpackChunkName: "component---src-pages-programme-index-js" */),
  "component---src-pages-programme-map-js": () => import("./../../../src/pages/programme/map.js" /* webpackChunkName: "component---src-pages-programme-map-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-event-page-js": () => import("./../../../src/templates/eventPage.js" /* webpackChunkName: "component---src-templates-event-page-js" */)
}

